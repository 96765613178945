import { Button } from "antd-mobile";
import { useAppTranslation } from "@features/mini-store/share/hook";
import { useQuery } from "react-query";
import { fetchStoreToken } from "@features/mini-store/share/api";
import BlockWrapper from "@features/design/components/BlockWrapper";
import { EyeVisibleIcon } from "@share/icons";
import React from "react";
import { Block } from "@features/main/store/interface";
import { openPopup } from "@features/block/store/edittingBlockSlice";
import { KEY_BLOCK } from "@share/configs/const";
import { useAppDispatch, useAppSelector } from "@app/hooks";
type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};
export default function BlockVirtualGift(props: Props) {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;

  const enable = blockData && blockData.enable;
  const dispatch = useAppDispatch();

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};
  const allowService = window["AllowStore"];

  const { t } = useAppTranslation();
  const loadStore = async () => {
    const store = await fetchStoreToken();
    if (store) {
      return store;
    }
  };
  const { isLoading, data: store } = useQuery("LoadStore", loadStore, {
    refetchOnWindowFocus: false,
  });
  const handlerClick = (event) => {
    if (event?.defaultPrevented) {
      return;
    }
    dispatch(
      openPopup({
        block: blockData,
        position_uid: positionSlug,
        tab_uid: tabUid,
        KEY: KEY_BLOCK.EDIT,
      })
    );
  };
  if (isLoading) {
    return <>loading</>;
  }
  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      onClick={handlerClick}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      <div className={"flex flex-col justify-center items-center"}>
        <img
          className={
            (!store?.virtual_gift_status ||
              !allowService?.allow_view_virtual_gift) &&
            "opacity-50"
          }
          src={
            "https://i.ecomobi.com/ssp/mini-store/virtual-gift/gift-cover.png"
          }
          alt=""
        />
        {!!store?.virtual_gift_status &&
          allowService?.allow_view_virtual_gift && (
            <>
              <h4 className={"w-full text-center break-words pt-2 pb-4"}>
                {t("ss_gift_invitation", { user: store.name })}
              </h4>
              <Button
                // className={`w-full button-border-radius button-background-color button-border-width button-border-color button-border-style button-text-color button-box-shadow middle-button`}
                className={`w-full pp-button`}
              >
                {t("ss_gift_button_preview")}
              </Button>
            </>
          )}
      </div>
    </BlockWrapper>
  );
}
