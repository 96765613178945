import { useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";
import {
  BLOCK_DATA_SAVE_MIDDLEWARE,
  BLOCK_TYPE,
} from "@features/block/block.conf";
import { closePopup } from "@features/block/store/edittingBlockSlice";
import { setParamsTab } from "@features/main/store/dynamicSlice";
import { POSITION_SLUG } from "@features/main/store/interface";
import {
  addBlock,
  updateBlock,
  updateTabsBody,
} from "@features/main/store/positionsSlice";
import FullScreenPopup from "@share/components/full-screen-popup";
import {
  KEY_BLOCK,
  POSITION_MAIN_LAYOUT,
  TAB_GHIM_STYLE,
  TEMPLATE_KEY,
} from "@share/configs/const";
import { CloseCircleIconV2, InformationIcon } from "@share/icons";
import { handleCapitalizeFirstLetter } from "@share/lib";
import gaEvents, {
  ACTION_CONFIG,
  BLOCK_TYPE_EVENT_CONFIG,
  CATEGORY_CONFIG,
} from "@share/lib/ga-events";
import { Modal } from "antd-mobile";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Block from "../Block";
import BlockEditorWrapper from "../BlockEditorWrapper";
import TemplateOverWriteComponent from "@features/block/components/TemplateComponent/TemplateOverWriteComponent";
import TemplateSaving from "@features/block/components/TemplateComponent/TemplateSaving";
import { BlockEditV2Context } from "@features/block/context/context";
import { dataService } from "@share/configs/rxjs";
import { selectProfile } from "@features/user/userSlice";
import { runExtraFunction } from "./extraFunction";
import { fetchSiteSetting } from "@share/api";
import { ACTION_TYPE } from "./extraFunction/constant";

export const runMiddleware = async (
  blockType,
  data,
  extraData,
  extraFunction
) => {
  console.log("blockType", blockType);

  const middleware = BLOCK_DATA_SAVE_MIDDLEWARE[blockType];
  console.log("middleware", middleware);

  if (middleware) {
    return await middleware(data, extraData, extraFunction).catch((e) => {
      console.log(e);
      throw e;
    });
  }
  return data;
};

export default function BlockEdit(props) {
  const {
    handleRedirectTabs,
    handleCloseFloatingAddBlock,
    handleOpenFloatingAddBlock,
  } = props;

  const scrollBottomRef = useRef(null);

  const visible = useSelector<RootState, boolean>(
    (state) => state.edittingBlock.open
  );
  const edittingBlock = useSelector<RootState, any>(
    (state) => state.edittingBlock
  );
  const dynamicConfig = useSelector<RootState, any>(
    (state) => state.dynamicConfig
  );
  const siteSettings = useSelector<RootState, any>(
    (state) => state.siteSettings
  );

  const [showFooterBlockEdit, setShowFooterBlockEdit] = useState<boolean>(true);

  const blockTabId = useAppSelector((state) => {
    if (!siteSettings.id) {
      return null;
    }
    return state.positions
      .find((position) => position.slug == "body")
      .block_list.find((block) => block.block_type == BLOCK_TYPE.TAB).id;
  });

  const extraData = {
    // id: siteSettings.id,
    user_id: siteSettings.user_id,
  };
  const positions = useSelector<RootState, any>((state) => state.positions);

  const edittingBlockData = edittingBlock && edittingBlock.block_data;
  const tabUid = edittingBlock && edittingBlock.tab_uid;
  const positionSlug = edittingBlock && edittingBlock.position_uid;
  const positionId = edittingBlock && edittingBlock.position_id;
  const sortOrder = edittingBlock && edittingBlock.sort_order;
  const KEY = edittingBlock && edittingBlock.KEY;
  const listTabInBody = positions?.find((x) => x?.slug === "body")
    ?.block_list[0]?.content_attributes?.tabs;

  const dispatch = useDispatch();

  const [blockDataState, setBlockDatatState] = useState(null);
  const [isSaveButtonDisable, setIsSaveButtonDisable] = useState(true);
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
  const [isEditStatus, setIsEditStatus] = useState(false);
  const [isOpenTemplate, setIsOpenTemplate] = useState(false);
  const [isAddNewTemplate, setIsAddNewTemplate] = useState(false);
  const [dataFromDesign, setDataFromDesign] = useState(null);
  const [isShowModalHandleLogicTemplate, setIsShowModalHandleLogicTemplate] =
    useState(false);

  const { t } = useTranslation();
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  useEffect(() => {
    if (!visible) {
      setBlockDatatState(null);
      setIsSaveButtonDisable(true);
      setIsEditStatus(false);
      setIsSaveButtonLoading(false);
    }
  }, [visible]);

  useEffect(() => {
    const subscription = dataService.getObservable().subscribe((data: any) => {
      if (data && Object.keys(data).length > 0) {
        setDataFromDesign((state) => ({
          ...state,
          ...data,
        }));
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  const handleUpdateTabsBody = (data) => {
    dispatch(updateTabsBody({ data }));
  };

  const extraFunction = {
    handleUpdateTabsBody,
  };

  const getEventGA = () => {
    let event = "";
    const indexTab =
      listTabInBody?.findIndex((item) => item.uid === tabUid) + 1;
    switch (positionSlug) {
      case POSITION_SLUG.HEADER:
        event = "Header_AddBlock";
        break;
      case POSITION_SLUG.FOOTER:
        event = "Footer_AddBlock";
        break;
      case POSITION_SLUG.BODY:
        event = `BodyTab${indexTab}_AddBlock`;
        break;
      case POSITION_SLUG.EXPAND:
        event = "Extended_AddBlock";
        break;
      default:
      // to do
    }
    return event;
  };

  const getLabelGA = () => {
    let label = "";
    const indexTab =
      listTabInBody?.findIndex((item) => item.uid === tabUid) + 1;
    const blockType = edittingBlockData?.block_type;
    switch (positionSlug) {
      case POSITION_SLUG.HEADER:
        label = "Header";
        break;
      case POSITION_SLUG.FOOTER:
        label = "Footer";
        break;
      case POSITION_SLUG.BODY:
        label = `Body - Tab ${indexTab}`;
        break;
      case POSITION_SLUG.EXPAND:
        if (blockType === BLOCK_TYPE.IMAGE_POPUP) {
          label = "Extended - Popup";
        } else if (
          blockType === BLOCK_TYPE.BRAND_GIFT_FOOTER ||
          blockType === BLOCK_TYPE.TRUTH_OR_DARE_FOOTER ||
          blockType === BLOCK_TYPE.GIFT_FOOTER
        ) {
          label = "Extended - Floating Icons";
        }
        break;
      default:
      // to do
    }
    return label;
  };

  async function handleRunExtraFunction(blockDataState, dispatch, actionType) {
    if (blockDataState?.content_attributes?.use_extra_function) {
      let responseSite = null;
      try {
        responseSite = await fetchSiteSetting(siteSettings.user_id);
      } catch (error) {
        console.log(error);
      }
      if(responseSite) {
        const positions = responseSite.positions;
        runExtraFunction(blockDataState, positions, dispatch, actionType);
      }
    }
  }

  const handleSaveBlock = async () => {
    setIsSaveButtonLoading(true);
    console.log("1111111111111111");

    try {
      var data = await runMiddleware(
        blockDataState?.block_type,
        blockDataState,
        extraData,
        extraFunction
      );
    } catch (e) {
      return;
    }

    const blockType =
      BLOCK_TYPE_EVENT_CONFIG[blockDataState?.block_type?.toUpperCase()] ||
      handleCapitalizeFirstLetter(blockDataState.block_type);

    if (KEY === KEY_BLOCK.EDIT) {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.BLOCK,
        `Block_Edit_${blockType}`,
        blockType,
        ACTION_CONFIG.EDIT
      );

      dispatch(
        updateBlock({
          tabUid,
          positionSlug,
          blockData: data,
        })
      );
    } else {
      let newSortOrder = 1;
      if (tabUid) {
        const tabBlockList = listTabInBody.find(
          (tab) => tab.uid == tabUid
        )?.block_list;
        if (tabBlockList?.length > 0) {
          newSortOrder =
            1 + Math.max(...tabBlockList.map((item) => item.sort_order));
        }
      } else {
        const positionBlockList = positions.find(
          (item) => item.slug == positionSlug
        ).block_list;
        if (positionBlockList?.length > 0) {
          newSortOrder =
            1 + Math.max(...positionBlockList.map((item) => item.sort_order));
        }
      }
      if (sortOrder) {
        newSortOrder = sortOrder;
      }
      const blockData = {
        ...data,
        tab_uid: tabUid ? tabUid : "0",
        position_id: tabUid ? "0" : positionId,
        parent_id: tabUid ? blockTabId : "0",
        sort_order: newSortOrder,
      };
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.BLOCK_TYPE,
        `BlockType_Add_${blockType}`,
        blockType,
        ACTION_CONFIG.ADD
      );
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.POSITION,
        getEventGA(),
        getLabelGA(),
        ACTION_CONFIG.ADD_BLOCK
      );
      dispatch(
        addBlock({
          tabUid,
          positionSlug,
          blockData: blockData,
          positionId,
        })
      );
    }
    
    handleRunExtraFunction(blockDataState, dispatch, ACTION_TYPE.SAVE);
    
    dispatch(closePopup());
    handleRedirectTabs && handleRedirectTabs();
    handleCloseFloatingAddBlock && handleCloseFloatingAddBlock();
    dispatch(
      setParamsTab({
        data: {
          activeKeyTab: tabUid,
        },
      })
    );
    setIsSaveButtonLoading(false);

    if (KEY !== KEY_BLOCK.EDIT) {
      const uid = blockDataState?.uid;
      if (uid) {
        const element = document.getElementById(uid);
        if (element) {
          const headerState = positions.find(
            (x) => x.slug === POSITION_MAIN_LAYOUT.HEADER
          );
          const blockListHeader = headerState && headerState.block_list;
          const tabBarBlock =
            blockListHeader &&
            blockListHeader.find((x) => x.block_type === BLOCK_TYPE.TAB_BAR);
          const tabMenuStyle = tabBarBlock && tabBarBlock.style_attributes;

          const position_style = tabMenuStyle && tabMenuStyle.position_style;
          const ghim_style = tabMenuStyle && tabMenuStyle.ghim_style;
          let tabMenuHeight = 0;
          if (
            ghim_style === TAB_GHIM_STYLE.ALWAYS_GHIM &&
            position_style === "top"
          ) {
            const tabMenuAlwaysGhimElement = document.querySelector(
              ".tab-design-always-ghim"
            ) as HTMLElement;
            tabMenuHeight = tabMenuAlwaysGhimElement?.offsetHeight ?? 0;
          }
          const tabBarHeaderElement = document.querySelector(
            ".tab-bar-design-header"
          ) as HTMLElement;
          const tabBarHeaderHeight = tabBarHeaderElement?.offsetHeight ?? 0;
          setTimeout(() => {
            // element?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            window.scrollTo({
              behavior: "smooth",
              top:
                element?.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                tabBarHeaderHeight -
                tabMenuHeight,
            });
          }, 400);
          const blockClass = document
            .getElementById(uid)
            .getElementsByClassName("block-class") as any;
          blockClass[0]?.style.setProperty("outline", "solid 2px #00B578");
          blockClass[0]?.style.setProperty("outline-offset", "-2px");
          setTimeout(() => {
            blockClass[0]?.style.setProperty("outline", "unset");
            blockClass[0]?.style.setProperty("outline-offset", "unset");
          }, 2000);
        }
      }
    }
  };

  const handleCancelBlock = async () => {
    setShowFooterBlockEdit(true);
    const blockType =
      BLOCK_TYPE_EVENT_CONFIG[
        blockDataState && blockDataState?.block_type?.toUpperCase()
      ] || handleCapitalizeFirstLetter(blockDataState?.block_type);
    if (isEditStatus) {
      Modal.confirm({
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        title: <div>{t("ss_builder_cancel_edit_block_title")}</div>,
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_cancel_edit_block_content")}
          </div>
        ),
        confirmText: t("ss_builder_continue_edit_block_label"),
        cancelText: t("ss_builder_cancel_block_btn_label"),
        onConfirm: () => {
          return;
        },
        onCancel: () => {
          if (KEY === KEY_BLOCK.EDIT) {
            gaEvents.submitEventV2(
              passioPageProfile,
              CATEGORY_CONFIG.BLOCK,
              `Block_Cancel_${blockType}`,
              blockType,
              ACTION_CONFIG.CANCEL
            );
          } else {
            gaEvents.submitEventV2(
              passioPageProfile,
              CATEGORY_CONFIG.BLOCK_TYPE,
              `BlockType_Cancel_${blockType}`,
              blockType,
              ACTION_CONFIG.CANCEL
            );
          }
          dispatch(closePopup());
        },
      });
    } else {
      if (KEY === KEY_BLOCK.EDIT) {
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.BLOCK,
          `Block_Cancel_${blockType}`,
          blockType,
          ACTION_CONFIG.CANCEL
        );
      } else {
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.BLOCK_TYPE,
          `BlockType_Cancel_${blockType}`,
          blockType,
          ACTION_CONFIG.CANCEL
        );
      }
      dispatch(closePopup());
    }
    handleRunExtraFunction(blockDataState, dispatch, ACTION_TYPE.CANCEL);
  };

  const handleCloseTemplatePopup = () => {
    setIsOpenTemplate(false);
  };
  const handleOpenTemplatePopup = () => {
    setIsOpenTemplate(true);
  };

  const handleOpenAndHidePopupAddNewTemplate = (value) => {
    setIsAddNewTemplate(value);
  };

  const handleLogicShowModalChooseOptionTemplate = (value) => {
    setIsShowModalHandleLogicTemplate(value);
  };

  const dataTemplateSaving = useMemo(() => {
    return {
      block_type: BLOCK_TYPE.COMBINED,
      title: "",
      image: "",
      status: 1,
      tags: [],
      block_space: blockDataState?.style_attributes?.block_space,
      block_data: blockDataState?.content_attributes?.blocks,
      is_using_global_block_settings:
        blockDataState?.style_attributes?.is_applied_block_global_settings ||
        false,
      advanced_settings:
        blockDataState?.style_attributes?.advanced_setting || {},
    };
  }, [blockDataState]);

  const defaultBlockData = useMemo(() => {
    let blockData = edittingBlockData;
    const blockType = edittingBlockData?.block_type;
    switch (blockType) {
      case BLOCK_TYPE.AFF_PRODUCT_GROUP:
        blockData = {
          ...edittingBlockData,
          content_attributes: {
            ...edittingBlockData.content_attributes,
            ...dataFromDesign,
          },
        };
        break;
    }
    return blockData;
  }, [edittingBlockData, dataFromDesign]);

  return (
    <BlockEditV2Context.Provider
      value={{
        handleOpenTemplatePopup,
        handleOpenAndHidePopupAddNewTemplate,
        isShowModalHandleLogicTemplate,
        handleLogicShowModalChooseOptionTemplate,
      }}
    >
      <FullScreenPopup
        visible={visible}
        onMaskClick={() => {
          dispatch(closePopup());
        }}
      >
        <BlockEditorWrapper
          block={blockDataState ?? edittingBlock}
          saveBtn={{
            disabled: isSaveButtonDisable,
            loading: isSaveButtonLoading,
          }}
          onUpdateBlockData={(data) => setBlockDatatState(data)}
          onCancelEdit={handleCancelBlock}
          onSave={handleSaveBlock}
          footer={showFooterBlockEdit}
        >
          <Block
            blockData={blockDataState ? blockDataState : edittingBlockData}
            setButtonStatus={(status) => setIsSaveButtonDisable(status)}
            setButtonLoading={(isLoading) => setIsSaveButtonLoading(isLoading)}
            setEditStatus={(status) => setIsEditStatus(status)}
            onUpdateData={(data) => setBlockDatatState(data)}
            handleCancelBlock={handleCancelBlock}
            setShowFooterBlockEdit={setShowFooterBlockEdit}
            defaultBlockData={defaultBlockData}
          />
        </BlockEditorWrapper>
      </FullScreenPopup>
      {isOpenTemplate && (
        <TemplateOverWriteComponent
          handleClose={handleCloseTemplatePopup}
          isOpenTemplate={isOpenTemplate}
          KEY={TEMPLATE_KEY.OVERWRITE}
          dataOverWrite={dataTemplateSaving}
          handleLogicShowModalChooseOptionTemplate={
            handleLogicShowModalChooseOptionTemplate
          }
        />
      )}
      {isAddNewTemplate && (
        <TemplateSaving
          visible={isAddNewTemplate}
          onMaskClick={() => setIsAddNewTemplate(false)}
          KEY={TEMPLATE_KEY.SAVE_NEW}
          templateDataSaving={dataTemplateSaving}
          handleCancelTemplateSaving={() => setIsAddNewTemplate(false)}
          handleLogicShowModalChooseOptionTemplate={
            handleLogicShowModalChooseOptionTemplate
          }
        />
      )}
    </BlockEditV2Context.Provider>
  );
}
