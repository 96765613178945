import {
  BlockGroupInterface,
  BlockSelectionInterface,
  COUNTRY_TH_BLOCK_HIDDEN,
} from "@features/block/config/block-selection";
import { useAppTranslation } from "@features/mini-store/share/hook";
import { List } from "antd-mobile";
import React from "react";
import BlockSelection from "../BlockSelection";
import { useSelector } from "react-redux";
import { RootState } from "@app/store";
import { COUNTRY_TH } from "@share/configs/const";

interface Props {
  group: BlockGroupInterface;
  onSelectBlock: (block: BlockSelectionInterface) => void;
  useAppSelector: any;
  positionSlug: string;
  tabUid: string;
}

const BlockGroup = (props: Props) => {
  const { group, useAppSelector, positionSlug, tabUid, onSelectBlock } = props;
  const { t } = useAppTranslation();
  const state = useAppSelector((state) => state);
  const allowService = window["AllowStore"];
  const country = state?.user?.kol?.country;
  const blockLists =
    country === COUNTRY_TH
      ? group.block_list?.filter(
          (block) => !COUNTRY_TH_BLOCK_HIDDEN.includes(block?.block_type)
        )
      : group.block_list;

  const header = (
    <div className="mx-[-12px] py-2">
      <div className="text-[15px] text-[#333333]">{t(group.name_key)}</div>
      <div className="text-xs text-[#8C8C8C]">{t(group.description_key)}</div>
    </div>
  );
  return (
    <List
      className="block-selection mx-3"
      header={header}
    >
      {blockLists.map((block) => (
        <BlockSelection
          init={(fn, setDisplayState, setWarningKey) =>
            fn(
              setDisplayState,
              setWarningKey,
              positionSlug,
              tabUid,
              state,
              allowService
            )
          }
          key={block.block_type}
          block={block}
          onClick={() => onSelectBlock(block)}
        />
      ))}
    </List>
  );
};

export default BlockGroup;
