import { SelectionStateEnum } from "./interface";

import { BASIC_GROUP } from "./data/basic-group";
import { ADVANCE_GROUP } from "./data/advance-group";
import { REVENUE_GROUP } from "./data/revenue-group";
import { AppState } from "@app/store";
import { POSITION_TYPE } from "./interface";
import { BLOCK_TYPE } from "../block.conf";

export interface onLoadFunctionInput {
  setSelectionState: (state: SelectionStateEnum) => void;
}

export interface BlockSelectionInterface {
  name_key: string;
  block_type: string;
  description_key: string;
  Icon: any;
  defaultState?: SelectionStateEnum;
  position?: POSITION_TYPE;
  onLoad?: (
    setDisplayState: (state: number) => void,
    setWarningKey: (key: string) => void,
    positionSlug: string,
    tabUid: string,
    state: AppState
  ) => void;
  disable_description?: React.ReactNode;
}

export interface BlockGroupInterface {
  name_key: string;
  description_key: string;
  slug: string;
  block_list: BlockSelectionInterface[];
}

export const BLOCK_SELECTION_GROUP: BlockGroupInterface[] = [
  BASIC_GROUP,
  ADVANCE_GROUP,
  REVENUE_GROUP,
];

export const COUNTRY_TH_BLOCK_HIDDEN = [
  BLOCK_TYPE.DIGITAL_PRODUCT,
  BLOCK_TYPE.GIFT,
  BLOCK_TYPE.GIFT_FOOTER,
  BLOCK_TYPE.DONATION,
  BLOCK_TYPE.BRAND_RATING,
  BLOCK_TYPE.BRAND_GIFT,
  BLOCK_TYPE.BRAND_GIFT_FOOTER,
  BLOCK_TYPE.TRUTH_OR_DARE,
  BLOCK_TYPE.TRUTH_OR_DARE_FOOTER,
];
