import { RootState } from "@app/store";
import { COUNTRY_TH_BLOCK_HIDDEN } from "@features/block/config/block-selection";
import { HIGHLIGHT_GROUP } from "@features/block/config/data/highlight-group";
import AutoScrollSelector from "@share/components/AutoScrollSelector";
import DndScrollable from "@share/components/dnd-scrollable";
import { COUNTRY_TH } from "@share/configs/const";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const BlockHighlight = ({ onSelectItem }) => {
  const { t } = useTranslation();
  const country = useSelector((state: RootState) => state?.user?.kol?.country);
  const blockLists =
    country === COUNTRY_TH
      ? HIGHLIGHT_GROUP.block_list?.filter(
          (block) => !COUNTRY_TH_BLOCK_HIDDEN.includes(block?.block_type)
        )
      : HIGHLIGHT_GROUP.block_list;
  return (
    <>
      <span className="text-[15px] font-normal leading-5 text-[#333333] mb-1">
        {t("ss_builder_hightlight_block_label")}
      </span>
      <DndScrollable>
        {blockLists.map((item, index) => (
          <div
            className="flex flex-col items-center justify-center gap-1 min-w-[85px] h-[86px] cursor-pointer"
            onClick={() => onSelectItem(item)}
            key={index}
          >
            <span>{item.Icon(48, 48)}</span>
            <span>{t(`${item.name_key}`)}</span>
          </div>
        ))}
      </DndScrollable>
    </>
  );
};

export default BlockHighlight;
