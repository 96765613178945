import { useAppSelector } from "@app/hooks";
import PreviewSetting from "@features/block/block-editor/Combined/components/PreviewSetting";
import {
  BLOCK_TYPE,
  BLOCK_TYPE_LANGUAGE_KEY,
} from "@features/block/block.conf";
import AffiliateProductTourOpening from "@features/block/block-editor/AffiliateProductGroup/AffiliateProductTourOpening";
import { AFFILIATE_SETTING_TOUR_CLASS_NAME } from "@features/block/block-editor/AffiliateProductGroup/AffiliateProductTourOpening/ProductSettingTour/setting-tour-config";
import MoreElement from "@features/block/components/MoreElement";
import {Block, CREATOR_ROLE_ENUM} from "@features/main/store/interface";
import IntroductionService from "@features/mini-store/block/BrandGift/IntroductionService";
import { DISABLE_BLOCK, ENABLE_BLOCK, IS_DISPLAY_IMPORT_PRODUCT_FILE, KEY_BLOCK } from "@share/configs/const";
import { BackAngelIcon, EyeVisibleIcon } from "@share/icons";
import { Button, NavBar } from "antd-mobile";
import cls from "classnames";
import { default as React, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import HidddenWarningUI from "../HiddenWarningUI";
import { useRouter } from 'next/router';

interface BlockEditorWrapper {
  block: Block;
  saveBtn: {
    disabled: boolean;
    loading: boolean;
  };
  onCancelEdit: () => void;
  onSave: () => void;
  footer: boolean;
  onUpdateBlockData?: (val) => void;
}

const BlockEditorWrapper: React.FunctionComponent<
  React.PropsWithChildren<BlockEditorWrapper>
> = (props) => {
  const { block, onCancelEdit, onSave, saveBtn, onUpdateBlockData } = props;

  const { t } = useTranslation();
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);
  const creator_role = passioPageProfile?.creator_role;
  const isAdminRole = useMemo(
      () => creator_role === CREATOR_ROLE_ENUM.ADMIN,
      [creator_role]
  );
  const router = useRouter();

  const storedBlock = useAppSelector((state) => state.edittingBlock);
  const keyBlock = storedBlock && storedBlock.KEY;
  const positionSlug = storedBlock && storedBlock.position_uid;
  const tabUid = storedBlock && storedBlock.tab_uid;
  const storedBlockData = storedBlock && storedBlock.block_data;
  const blockTitle = block.title ?? (storedBlockData && storedBlockData.title);

  const scrollBottomRef = useRef(null);
  const isEnable = block?.enable || ENABLE_BLOCK;
  const [forceVisiblePopoverHeader, setForceVisiblePopoverHeader] =
    useState(false);

  useEffect(() => {
    const castWindow = window as any;
    castWindow.triggerVisiblePopoverMoreElement = setForceVisiblePopoverHeader;
    () => {
      if (castWindow.triggerVisiblePopoverMoreElement) {
        castWindow.triggerVisiblePopoverMoreElement = undefined;
        setForceVisiblePopoverHeader(false);
      }
    };
  }, []);

  const handleUpdateStatus = (status) => {
    onUpdateBlockData({
      ...block,
      enable: status,
    });
  };

  const handeSetEnableBlock = () => {
    const newStatus = Object.is(isEnable, ENABLE_BLOCK)
      ? DISABLE_BLOCK
      : ENABLE_BLOCK;
    handleUpdateStatus(newStatus);
  };

  const renderExtraAction = () => {
    let action = <></>;
    switch (block.block_type) {
      case BLOCK_TYPE.BRAND_GIFT:
        action = <IntroductionService />;
        break;
      case BLOCK_TYPE.AFF_PRODUCT_GROUP:
        action = <AffiliateProductTourOpening />;
        break;
      case BLOCK_TYPE.COMBINED:
        action = <PreviewSetting blockData={block} />;
        break;
      default:
        break;
    }
    return action;
  };

  const buttonGroupTourClass = useMemo(() => {
    let btnClassName = "";
    switch (block.block_type) {
      case BLOCK_TYPE.AFF_PRODUCT_GROUP:
        btnClassName = AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_8;
        break;
      default:
        break;
    }
    return btnClassName;
  }, [block.block_type]);

  const blockTypeClass = block.block_type?.replaceAll("_", "-");
  const isDisplayUploadProductFile = Number(router?.query?.import) === IS_DISPLAY_IMPORT_PRODUCT_FILE &&
    storedBlock?.block_data?.block_type ===  BLOCK_TYPE.AFF_PRODUCT_GROUP

  return (
    <>
      <div className="block-editor-wrapper relative h-full">
        <div className="pb-[60px]">
          <div
            id={`block-${blockTypeClass}-editor-header`}
            className="sticky top-0 z-[12]"
          >
            <div
              className="relative bg-white"
              style={{
                boxShadow: "inset 0px -1px 0px #EEEEEE",
              }}
            >
              <NavBar
                backArrow={<BackAngelIcon />}
                onBack={onCancelEdit}
                className="w-full"
              >
                <div className="flex flex-row text-center items-center justify-center gap-[10px] text-[13px]">
                  <div className="text-ellipsis whitespace-nowrap overflow-hidden max-w-[70vw] md:max-w-[20vw] text-titleFontSize">
                    {t(
                      BLOCK_TYPE_LANGUAGE_KEY[
                        block.block_type ?? storedBlock.block_data.block_type
                      ]
                    ) ?? blockTitle}
                  </div>
                  <div className="absolute right-4 flex items-center justify-center gap-[8px]">
                    {renderExtraAction()}
                    {(keyBlock !== KEY_BLOCK.ADD_NEW ||
                     ( storedBlock?.block_data?.block_type ===
                        BLOCK_TYPE.COMBINED && isAdminRole) || isDisplayUploadProductFile) && (
                      <MoreElement
                        isEnable={isEnable}
                        sort_order={storedBlockData.sort_order}
                        positionSlug={positionSlug}
                        tabUid={tabUid}
                        blockUid={storedBlockData?.uid}
                        handeSetEnableBlock={handeSetEnableBlock}
                        blockType={storedBlockData.block_type}
                        type={storedBlockData.block_type}
                        visible={forceVisiblePopoverHeader}
                        dataBlock={block}
                        KEY={keyBlock}
                      />
                    )}
                  </div>
                </div>
              </NavBar>
            </div>
            {isEnable != ENABLE_BLOCK && (
              <HidddenWarningUI
                label={t("ss_builder_block_design_hiding_label")}
              />
            )}
          </div>
          {props.children}
        </div>
        {props.footer && (
          <div
            className={cls(
              "max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[10] bg-[#FFFFFF]",
              buttonGroupTourClass
            )}
          >
            <div className="w-1/2 text-center">
              <Button
                className="w-full"
                onClick={onCancelEdit}
              >
                {t("ss_builder_editor_btn-cancel")}
              </Button>
            </div>
            <div className="w-1/2 text-center">
              <Button
                className="w-full"
                color={"primary"}
                onClick={onSave}
                disabled={saveBtn.disabled}
                loading={saveBtn.loading}
              >
                {t("ss_builder_editor_btn-save")}
              </Button>
            </div>
          </div>
        )}
        <div ref={scrollBottomRef}></div>
      </div>
    </>
  );
};

export default BlockEditorWrapper;
