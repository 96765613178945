import { PRODUCT_MAX_DEFAULT, SLIDE } from "@share/configs/const";
import { Button, Image } from "antd-mobile";
import cloneDeep from "lodash/cloneDeep";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductStyleDisplay, {
  StyleAttributesInterface,
} from "../ProductStyleDisplay";

type Props = {
  style_attributes: StyleAttributesInterface;
  productGroupItem: any;
  see_more_btn_content: string;
  affiliateProductGroupData?: any;
  productGroupMaximum?: number;
  onUpdateProductGroupData: (productGroupData: any) => void;
};

const ProductListsDisplay = (props: Props) => {
  const {
    style_attributes,
    productGroupItem,
    see_more_btn_content,
    affiliateProductGroupData,
    productGroupMaximum,
    onUpdateProductGroupData,
  } = props;

  const { t } = useTranslation();

  const allProductLists = useMemo(() => {
    const productLists =
      productGroupItem?.content_attributes?.items?.filter(
        (x) => x?.enable === 1
      ) || [];
    return productLists;
  }, [productGroupItem]);

  const productListsRenderDefault = useMemo(() => {
    const allProductListsClone = cloneDeep(allProductLists);
    if (
      allProductListsClone &&
      allProductListsClone.length > PRODUCT_MAX_DEFAULT
    ) {
      allProductListsClone.length = PRODUCT_MAX_DEFAULT;
    }
    return allProductListsClone;
  }, [allProductLists]);

  const [productListsRender, setProductListRender] = useState(
    productListsRenderDefault
  );

  useEffect(() => {
    if (productListsRenderDefault) {
      setProductListRender(productListsRenderDefault);
    }
  }, [JSON.stringify(productListsRenderDefault)]);

  const isDisplayBtnSeeMore = useMemo(() => {
    if (
      allProductLists.length <= PRODUCT_MAX_DEFAULT ||
      productListsRender.length === allProductLists.length ||
      style_attributes.style_display === SLIDE
    ) {
      return false;
    } else return true;
  }, [allProductLists, productListsRender]);

  const handleSeeMoreProducts = useCallback(
    (event) => {
      event.stopPropagation();
      const allProductListsClone = cloneDeep(allProductLists);

      if (
        allProductLists.length - productListsRender.length <
        PRODUCT_MAX_DEFAULT
      ) {
        allProductListsClone.length =
          productListsRender.length +
          (allProductLists.length - productListsRender.length);
      } else {
        allProductListsClone.length =
          productListsRender.length + PRODUCT_MAX_DEFAULT;
      }
      setProductListRender(allProductListsClone);
    },
    [allProductLists, productListsRender]
  );

  const isEnabled = productGroupItem?.enable === 1;

  return (
    <>
      {allProductLists.length > 0 ? (
        <div>
          <div
            style={{
              opacity: !isEnabled ? "60%" : "",
            }}
          >
            <ProductStyleDisplay
              products={productListsRender}
              styleAttributes={style_attributes}
              affiliateProductGroupData={affiliateProductGroupData}
              onUpdateProductGroupData={onUpdateProductGroupData}
              productGroupMaximum={productGroupMaximum}
              allProductLists={allProductLists}
            />
          </div>
          {isDisplayBtnSeeMore && (
            <Button
              block
              // color="primary"
              size="middle"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "16px",
                gap: "5px",
              }}
              onClick={handleSeeMoreProducts}
              className="pp-button"
            >
              <div>{see_more_btn_content}</div>
            </Button>
          )}
        </div>
      ) : (
        <div
          className="flex flex-col justify-center text-center gap-2 mt-5 mb-[16px]"
          style={{
            opacity: !isEnabled ? "60%" : "",
          }}
        >
          <Image
            src="https://image.passio.eco/page-builder/page-builder/empty-product/Empty-product.svg"
            // width={80}
            height={80}
            className="mx-[auto]"
          />
          <div className="flex flex-col">
            <span
              className="lead-2"
              style={{
                color: "var(--text-color-6)",
              }}
            >
              {t("ss_builder_product_design_empty_title")}
            </span>
            <span
              style={{
                color: "var(--text-color-5)",
              }}
            >
              {t(
                "ss_builder_to_add_products_please_press_the_add_button_above"
              )}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductListsDisplay;
