import { AppState } from "@app/store";
import {
  BLOCK_TYPE,
  BLOCK_TYPE_LANGUAGE_KEY,
} from "@features/block/block.conf";
import { BLOCK_FILTER_GROUP_TYPE } from "@share/configs/const";
import {
  ButtonLinkIconColor,
  ImageIconColor,
  ImagePopupIcon,
  TextIconColor,
} from "@share/icons/block-icons";
import { BlockGroupInterface } from "../block-selection";

enum SelectionStateEnum {
  ENABLE = 1,
  DISABLE = 2,
  HIDDEN = 3,
}

export const BASIC_GROUP: BlockGroupInterface = {
  name_key: "ss_builder_basic_title",
  description_key: "ss_builder_basic_group_description",
  slug: BLOCK_FILTER_GROUP_TYPE.BASIC,
  block_list: [
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.BUTTON_LINK],
      block_type: BLOCK_TYPE.BUTTON_LINK,
      description_key: "ss_builder_block_button_link_description",
      Icon: (width, height) => (
        <ButtonLinkIconColor
          width={width}
          height={height}
        />
      ),
    },
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.IMAGE],
      block_type: BLOCK_TYPE.IMAGE,
      description_key: "ss_builder_block_image_description",
      Icon: (width, height) => (
        <ImageIconColor
          width={width}
          height={height}
        />
      ),
    },
    {
      name_key: BLOCK_TYPE_LANGUAGE_KEY[BLOCK_TYPE.TEXT],
      block_type: BLOCK_TYPE.TEXT,
      description_key: "ss_builder_block_text_description",
      Icon: (width, height) => (
        <TextIconColor
          width={width}
          height={height}
        />
      ),
    },
  ],
};
